import type { ISbStoryData } from 'storyblok-js-client'
import type { MenuStoryblok, NavigationStoryblok } from '~/types/storyblok'
import { Logger } from '~/composables/logger'

export type NavigationWithRelations = NavigationStoryblok & {
  sidebar?: ISbStoryData<MenuStoryblok>
  charging?: ISbStoryData<MenuStoryblok>
  installation?: ISbStoryData<MenuStoryblok>
}

export async function useNavigation(): Promise<NavigationWithRelations> {
  const storyblokApi = useStoryblokApi()

  try {
    const result = await storyblokApi.get('cdn/stories/config/navigation', {
      version: useStoryblokVersion(),
      resolve_relations: ['navigation.sidebar', 'navigation.charging', 'navigation.installation'],
    })

    return result.data.story.content
  } catch (e) {
    Logger.error(e, 'Unable to load HeaderMenu from Storyblok')
    throw e
  }
}
