<script setup lang="ts">
import EldonSymbolWhite from 'assets/logos/white/Eldon_Symbol-White.svg'
import { blokLink } from '~/helpers/blokLink'
import StoryblokRichtext from '~/components/Global/StoryblokRichtext.vue'

const blok = await useFooter()
</script>

<template>
  <footer v-if="blok" v-editable="blok" class="mt-20 bg-midnight text-white" aria-labelledby="footer-heading">
    <h2 id="footer-heading" class="sr-only">{{ blok.heading }}</h2>
    <div class="container mx-auto px-6 pb-8 pt-16 sm:pt-24 lg:px-8 lg:py-20">
      <div class="xl:grid xl:grid-cols-5 xl:gap-8">
        <div class="space-y-4 xl:col-span-3">
          <div class="text-2xl font-semibold">{{ blok.heading }}</div>
          <StoryblokRichtext :text="blok.text" dark class="text-sm leading-6" />
          <div class="space-y-6">
            <div
              v-if="blok.menu1"
              class="flex flex-col space-x-0 space-y-2 text-sm md:flex-row md:space-x-2 md:space-y-0">
              <div v-for="item in blok.menu1.content.items" :key="item.name">
                <NuxtLink v-if="item.link" :to="blokLink(item.link)" class="mt-2 hover:text-gray-400">
                  {{ item.name }}
                </NuxtLink>
              </div>
            </div>
            <div class="flex items-center space-x-4">
              <EldonSymbolWhite class="text-[70px]" />
              <div class="text-sm">{{ blok.copyright }}</div>
            </div>
          </div>
        </div>
        <div class="col-span-2 mt-5 grid space-y-10 sm:grid-cols-2 sm:space-y-0 xl:mt-0">
          <div>
            <div v-if="blok.menu2" class="space-y-4">
              <h5 v-if="blok.menu2.content.heading" class="text-xl font-semibold">
                {{ blok.menu2.content.heading }}
              </h5>
              <ul role="list" class="space-y-2">
                <li v-for="item in blok.menu2.content.items" :key="item.name">
                  <NuxtLink v-if="item.link" :to="blokLink(item.link)" class="hover:text-gray-400">
                    {{ item.name }}
                  </NuxtLink>
                </li>
              </ul>
            </div>
          </div>
          <div>
            <div v-if="blok.menu3" class="space-y-4">
              <h5 v-if="blok.menu3.content.heading" class="text-xl font-semibold">
                {{ blok.menu3.content.heading }}
              </h5>
              <ul role="list" class="space-y-2">
                <li v-for="item in blok.menu3.content.items" :key="item.name">
                  <NuxtLink v-if="item.link" :to="blokLink(item.link)" class="hover:text-gray-400">
                    {{ item.name }}
                  </NuxtLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
