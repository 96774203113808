<script setup lang="ts">
import { Bars3CenterLeftIcon, MagnifyingGlassIcon, XMarkIcon } from '@heroicons/vue/24/outline'
import EldonVerticalAlt1ColorReactive from 'assets/logos/color/Eldon_Vertical-Alt-1-Color-Reactive.svg'
import { blokLink } from '~/helpers/blokLink'

const {
  openSidebar,
  openSearch,
  openCharging,
  openInstallation,
  open: navbarOpen,
  closeAll: navbarCloseAll,
} = useNavbar()

const { sidebar: sidebarNavigation, charging: chargingMenu, installation: installationMenu } = await useNavigation()

const searchInput = ref<HTMLInputElement>()

const installationNavigationRowClass = computed(() => {
  const installationMenuLength: number = installationMenu ? installationMenu.content.items.length : 1

  // @see https://tailwindcss.com/docs/content-configuration#dynamic-class-names
  return {
    'grid-rows-1': Math.ceil(installationMenuLength / 3) === 1,
    'grid-rows-2': Math.ceil(installationMenuLength / 3) === 2,
    'grid-rows-3': Math.ceil(installationMenuLength / 3) === 3,
    'grid-rows-4': Math.ceil(installationMenuLength / 3) === 4,
    'grid-rows-5': Math.ceil(installationMenuLength / 3) === 5,
    'grid-rows-6': Math.ceil(installationMenuLength / 3) === 6,
  }
})

function toggleSidebar() {
  navbarOpen(openSidebar, !openSidebar.value)
}

function toggleSearch() {
  navbarOpen(openSearch, !openSearch.value)

  if (!openSearch.value) {
    clearSearch()
  }
}

function clearSearch() {
  if (searchInput.value) {
    searchInput.value.value = ''
  }
}

function addFocus() {
  nextTick(() => {
    if (searchInput.value) {
      searchInput.value.focus()
    }
  })
}

function toggleCharging() {
  navbarOpen(openCharging, !openCharging.value)
}

function toggleInstallation() {
  navbarOpen(openInstallation, !openInstallation.value)
}

const onSubmitSearch = async () => {
  await useRouter().push({
    force: true,
    path: '/search',
    query: {
      q: searchInput.value?.value,
    },
  })

  openSearch.value = false
  clearSearch()
}

onMounted(() => {
  document.addEventListener('keyup', function (event) {
    if (event.code === 'Escape') {
      navbarCloseAll()
    }
  })
})
</script>

<template>
  <nav>
    <div class="relative z-40 w-full bg-white shadow">
      <div class="mx-auto max-w-7xl px-6">
        <div class="flex h-[130px]">
          <div class="flex w-2/4 items-center">
            <div class="flex items-center">
              <button
                class="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500"
                @click="toggleSidebar">
                <span class="absolute -inset-0.5" />
                <span class="sr-only">Open main menu</span>
                <Bars3CenterLeftIcon v-if="!openSidebar" class="block size-6" aria-hidden="true" />
                <XMarkIcon v-else class="block size-6" aria-hidden="true" />
              </button>
            </div>
            <div class="ml-10 mr-20 hidden flex-1 items-center justify-around md:flex">
              <button
                v-if="chargingMenu"
                type="button"
                class="bg-white p-1 hover:text-forest-700"
                :class="{ 'text-forest-700': openCharging }"
                @click="toggleCharging">
                {{ chargingMenu.content.heading }}
              </button>
              <button
                v-if="installationMenu"
                type="button"
                class="bg-white p-1 hover:text-forest-700"
                :class="{ 'text-forest-700': openInstallation }"
                @click="toggleInstallation">
                {{ installationMenu.content.heading }}
              </button>
            </div>
          </div>
          <div class="flex w-32 items-center text-[90px]">
            <NuxtLink href="/">
              <EldonVerticalAlt1ColorReactive />
            </NuxtLink>
          </div>
          <div class="flex w-2/4 items-center justify-end pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
            <button
              type="button"
              class="relative rounded-full bg-white p-1 text-gray-400 hover:text-gray-500"
              @click="toggleSearch">
              <span class="absolute -inset-1.5" />
              <span class="sr-only">Open search</span>
              <MagnifyingGlassIcon v-if="!openSearch" class="size-6" aria-hidden="true" />
              <XMarkIcon v-else class="block size-6" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- SIDEBAR -->
    <Transition
      enter-active-class="transition ease-in-out duration-300 transform"
      enter-from-class="-translate-x-full"
      enter-to-class="translate-x-0"
      leave-active-class="transition ease-in-out duration-300 transform"
      leave-from-class="translate-x-0"
      leave-to-class="-translate-x-full">
      <div
        v-show="openSidebar"
        class="fixed left-0 top-0 z-50 h-screen w-4/5 bg-white shadow md:w-[700px]"
        tabindex="-1">
        <div class="h-screen space-y-1 overflow-y-auto p-8">
          <div class="flex justify-end">
            <button
              class="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500"
              @click="toggleSidebar">
              <span class="absolute -inset-0.5" />
              <span class="sr-only">Close main menu</span>
              <XMarkIcon class="block size-8" aria-hidden="true" />
            </button>
          </div>
          <div class="py-4">
            <div v-for="story in sidebarNavigation" :key="story.content._uid" v-editable="story.content" class="mt-8">
              <NuxtLink
                v-if="blokLink(story.content.article_link)?.length > 1"
                :to="blokLink(story.content.article_link)"
                class="mb-2 inline-block text-xl font-bold hover:text-forest-700 focus:text-forest-700"
                @click="toggleSidebar">
                {{ story.content.heading }}
              </NuxtLink>
              <h4 v-else class="mb-2 text-xl font-bold">{{ story.content.heading }}</h4>

              <ul class="space-y-2">
                <li v-for="item in story.content.items" :key="item.name">
                  <NuxtLink
                    :to="blokLink(item.link)"
                    class="hover:text-forest-700 focus:text-forest-700"
                    @click="toggleSidebar">
                    {{ item.name }}
                  </NuxtLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Transition>

    <!-- SEARCH -->
    <Transition
      enter-active-class="transition ease-in-out duration-300 transform"
      enter-from-class="-translate-y-full"
      enter-to-class="translate-y-0"
      leave-active-class="transition ease-in-out duration-300 transform"
      leave-from-class="translate-y-0"
      leave-to-class="-translate-y-full"
      @after-enter="addFocus"
      @after-leave="clearSearch">
      <div v-show="openSearch" class="absolute z-30 h-[100px] w-screen bg-cream shadow" tabindex="-1">
        <div class="container mx-auto flex h-full">
          <div class="flex grow items-center justify-center">
            <label for="search" class="sr-only ml-px block pl-4 text-sm font-medium leading-6 text-gray-900">
              Search
            </label>
            <div class="relative mx-5 w-full rounded-full lg:w-1/2">
              <div class="pointer-events-none absolute inset-y-0 left-0 z-20 flex items-center pl-6">
                <MagnifyingGlassIcon class="size-5 text-black" aria-hidden="true" />
              </div>
              <form @submit.prevent="onSubmitSearch">
                <div class="relative w-full">
                  <input
                    id="search"
                    ref="searchInput"
                    type="text"
                    name="search"
                    class="block w-full rounded-full border-0 py-4 pl-14 text-gray-900 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6"
                    placeholder="Hitta rätt på Eldon Installation..."
                    required />
                  <button
                    type="submit"
                    class="absolute right-0 top-0 h-full rounded-l-none rounded-r-full border border-midnight-700 bg-midnight-700 py-4 pl-6 pr-7 text-sm font-medium text-white hover:bg-midnight-800 focus:outline-none focus:ring-2 focus:ring-midnight-300 dark:bg-midnight-600 dark:hover:bg-midnight-700 dark:focus:ring-midnight-800">
                    Sök
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Transition>

    <!-- CHARGING -->
    <Transition
      v-if="chargingMenu"
      enter-active-class="transition ease-in-out duration-300 transform"
      enter-from-class="-translate-y-full"
      enter-to-class="translate-y-0"
      leave-active-class="transition ease-in-out duration-300 transform"
      leave-from-class="translate-y-0"
      leave-to-class="-translate-y-full">
      <div v-show="openCharging" class="absolute z-30 w-screen bg-cream shadow" tabindex="-1">
        <div class="mx-auto flex max-w-7xl items-stretch justify-center">
          <NuxtLink
            v-for="item in chargingMenu.content.items"
            :key="item._uid"
            :to="blokLink(item.link)"
            class="relative flex w-[150px] border-b-4 border-transparent p-3 text-center text-sm leading-6 hover:border-b-forest-700 hover:bg-cream-25 sm:flex-col sm:p-6 sm:pt-8"
            @click="toggleCharging">
            <div class="flex flex-none items-center justify-center text-4xl">
              <NuxtImg
                v-if="item.icon?.filename"
                provider="storyblok"
                width="75"
                height="75"
                :src="item.icon.filename" />
            </div>
            <div>
              {{ item.name }}
            </div>
          </NuxtLink>
        </div>
      </div>
    </Transition>

    <!-- INSTALLATION -->
    <Transition
      v-if="installationMenu"
      enter-active-class="transition ease-in-out duration-300 transform"
      enter-from-class="-translate-y-full"
      enter-to-class="translate-y-0"
      leave-active-class="transition ease-in-out duration-300 transform"
      leave-from-class="translate-y-0"
      leave-to-class="-translate-y-full">
      <div v-show="openInstallation" class="absolute z-30 w-screen bg-cream shadow" tabindex="-1">
        <div class="mx-auto h-full max-w-7xl p-10">
          <div class="grid grid-cols-1 gap-x-8 gap-y-10 lg:grid-cols-3">
            <div class="lg:col-span-2">
              <h3 class="mb-4 text-xl font-semibold">{{ installationMenu.content.heading }}</h3>
              <div class="grid grid-flow-col grid-cols-3 gap-x-6 sm:gap-x-8" :class="installationNavigationRowClass">
                <NuxtLink
                  v-for="item in installationMenu.content.items"
                  :key="item._uid"
                  :to="blokLink(item.link)"
                  class="flex items-center gap-x-2 p-2 hover:bg-cream-25"
                  @click="toggleInstallation">
                  <span class="w-[15px]">
                    <NuxtImg
                      v-if="item.icon?.filename"
                      provider="storyblok"
                      width="15"
                      height="15"
                      :src="item.icon.filename" />
                  </span>
                  <span>{{ item.name }}</span>
                </NuxtLink>
              </div>
            </div>
            <NuxtLink
              v-if="installationMenu.content.article_link"
              :to="blokLink(installationMenu.content.article_link)"
              class="hidden rounded-lg bg-white transition-shadow hover:shadow-md sm:flex"
              @click="toggleInstallation">
              <div class="w-2/6">
                <NuxtImg
                  v-if="installationMenu.content.article_image?.filename"
                  :alt="installationMenu.content.article_title || ''"
                  :src="installationMenu.content.article_image.filename"
                  provider="storyblok"
                  sizes="400px"
                  format="webp"
                  class="size-full rounded-l-lg object-cover" />
              </div>
              <div class="w-4/6 p-4 text-sm">
                <div>{{ installationMenu.content.article_title }}</div>
                <div>{{ installationMenu.content.article_description }}</div>
              </div>
            </NuxtLink>
          </div>
        </div>
      </div>
    </Transition>
  </nav>
</template>
