<script setup lang="ts">
import Navbar from '~/components/Global/Navbar.vue'
import Footer from '~/components/Global/Footer.vue'

useHead({
  htmlAttrs: {
    lang: 'en',
  },
})
</script>

<template>
  <main>
    <Navbar />

    <div class="mx-auto max-w-7xl px-6">
      <slot />
    </div>

    <Footer />
  </main>
</template>
