import { ISbStoryData } from 'storyblok-js-client'
import type { FooterStoryblok, MenuStoryblok } from '~/types/storyblok'
import { Logger } from '~/composables/logger'

export type FooterWithRelations = FooterStoryblok & {
  menu1?: ISbStoryData<MenuStoryblok>
  menu2?: ISbStoryData<MenuStoryblok>
  menu3?: ISbStoryData<MenuStoryblok>
}

export async function useFooter(): Promise<FooterWithRelations> {
  const storyblokApi = useStoryblokApi()

  try {
    const result = await storyblokApi.get('cdn/stories/config/footer', {
      version: useStoryblokVersion(),
      resolve_links: 'url',
      resolve_relations: ['footer.menu1', 'footer.menu2', 'footer.menu3'],
    })

    return result.data.story.content
  } catch (e) {
    Logger.error(e, 'Unable to load stories/config/footer from Storyblok')
    throw e
  }
}
