import { Ref, ref } from 'vue'

export function useNavbar() {
  const openSidebar = ref(false)
  const openSearch = ref(false)
  const openCharging = ref(false)
  const openInstallation = ref(false)

  function open(navigation: Ref<boolean>, open: boolean) {
    if (open) {
      closeAll()
    }

    navigation.value = open
  }

  function closeAll() {
    openSidebar.value = false
    openSearch.value = false
    openCharging.value = false
    openInstallation.value = false
  }

  return { openSidebar, openSearch, openCharging, openInstallation, open, closeAll }
}
